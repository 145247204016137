<div class="banner life-banner">
  <h2>与培风图南一起构建EDA新未来</h2>
  <p>我们坚信培风图南是由每一位有才华、有梦想、有创造力的成员组成和定义的。加入培风图南，成就你的梦想！</p>
</div>
<div class="container life">
  <h2>全面薪酬</h2>
  <img src="assets/img/life1.png" alt="" class="pay">

  <h2>员工关怀</h2>
  <div class="item-container">
    <div class="item"
      *ngFor="let label of ['拓展团建','年度旅游','健康体检','节庆礼品','员工健身房','母婴室','一对一带教','家庭开放日','亲子图书馆'];let i = index">
      <img [src]="'assets/img/life-icon-' + i + '.png'" alt=""
      [ngClass]="{'big-img':i==1||i==3||i==6||i==8,
                  'large-img':i==4||i==7}">
      <span>{{label}}</span>
    </div>
  </div>

  <h2>学长学姐说</h2>
  <app-senior></app-senior>
</div>
