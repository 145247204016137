<div class="banner home-banner">
  <h1>Microelectronics Fabrication</h1>
  <span class="red-span">Predictable Success</span>
  <p>with computational models and data-driven processes</p>
</div>
<ul class="icon-list">
  <li (click)="goToRouter('/product/opc')">
    <img src="assets/img/opc-icon.png" alt="OPC">
    <p>OPC</p>
  </li>
  <li (click)="goToRouter('/product/tcad')">
    <img src="assets/img/tCAD-icon.png" alt="TCAD">
    <p>TCAD</p>
  </li>
  <li (click)="goToRouter('/product/basic-ip')">
    <img src="assets/img/IP-icon.png" alt="IP" style="width:38px;">
    <p>基础IP</p>
  </li>
  <li (click)="goToRouter('/product/technology-development')">
    <img src="assets/img/service-icon.png" alt="technology">
    <p>工艺研发</p>
  </li>
</ul>
