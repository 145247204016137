import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.css']
})
export class ProductsListComponent implements OnInit {
  curNav:string = 'opc';

  constructor(private router: Router) {
    const self = this;
    self.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd && event.url) {
        if(event.url.includes('opc')){
          self.curNav = 'opc';
        }else if(event.url.includes('tcad')){
          self.curNav = 'tcad';
        }else if(event.url.includes('ip')){
          self.curNav = 'ip';
        }else if(event.url.includes('technology')){
          self.curNav = 'technology';
        }else{
          self.curNav = 'opc';
        }
      }
    });
  }

  ngOnInit(): void {
    const self = this;
    let nav:string = sessionStorage.getItem('productNav');
    if(nav){
      self.curNav = nav;
      sessionStorage.removeItem('productNav');
    }
  }

  goToRouter(url:string): void {
    const self = this;
    self.router.navigate([url]);
  }

  changeNav(nav:string): void {
    const self = this;
    self.curNav = nav;
  }

}
