import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit {
  news:any;
  contentTags:any;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    const self = this;
    if(sessionStorage.getItem('news')){
      self.news = JSON.parse(sessionStorage.getItem('news'));
      self.contentTags = Object.keys(self.news.content);
    }else {
      self.router.navigate(["/news"]);
    }
  }

}
