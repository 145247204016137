<h3>服务概览</h3>
<p>提供面对商业化晶圆厂需求的全流程、多层次的工艺平台研发辅助服务，技术节点完全覆盖CMOS/BCD成熟节点到先进FinFET节点。同时，我们提供灵活和定制化的服务项目组合，因地制宜，最大化我们给客户服务的性价比。</p>

<p class="color-red">基础服务</p>
<ul class="ul-list">
  <li><span>全套TQV设计、测试、分析、报告</span></li>
  <li><span>SPICE/PDK 开发</span></li>
  <li><span>基础 IP 开发</span></li>
</ul>
<p class="color-red">工艺技术研发支持</p>
<ul class="ul-list">
  <li><span>提供工艺改善方案，全面提升元器件性能与定制化需求、工艺可靠性、测试芯片良率</span></li>
  <li>
    <span>EDA工具应用与建模支持</span>
    <ul class="ul-list">
      <li><span>OPC模型和掩膜修正配方开发</span></li>
      <li><span>TCAD工艺/器件仿真模型参数设置和校准</span></li>
    </ul>
  </li>
</ul>
<p class="color-red">DTCO与研发自动化</p>
<ul class="ul-list">
  <li><span>技术平台 (工艺与基础IP) 定义、定制化、竞争力优化</span></li>
  <li><span>研发流程自动化、智能化</span></li>
</ul>

<h3>技术竞争力</h3>
<p class="color-red">集成电路工艺平台研发能力</p>
<p>技术服务团队拥有强大的工艺平台研发背景和能力，在工艺集成、元器件优化、可靠性提升、良率改善、可制造性设计、SPICE/PDK开发、DTCO、研发流程自动化等领域，领先同行。</p>

<p class="color-red">研发效率与自动化能力</p>
<p>特有的高精度可寻址阵列（Precision Array）技术，提供业界唯一的 &lt;1pA低漏电测量能力。为在先进工艺节点实现快速、精确的高并发圆片级测量，提供了全新的解决方案。</p>
<p>特有的TQV编译器，可以根据设计规则自动生成CMOS和BCD等工艺中TQV测试图形的被测器件（DUT）版图，并完成自动布图和绕线，大大提高TQV测试结构设计的效率。</p>
<p>自研的可编程存储器自测试（pMBIST）电路和存储器电路编译器，可以快速生成SRAM 良率测试芯片 (SRAM yield testchip)。片上自测试及其配套的测试机可以大幅度提高良率及产品级可靠性测试的带宽，加速工艺研发进程。</p>
