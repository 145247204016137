import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { CompanyComponent } from './company/company.component';
import { ContactComponent } from './contact/contact.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { JobsListComponent } from './jobs-list/jobs-list.component';
import { JobDetailComponent } from './job-detail/job-detail.component';
import { Gds2MeshComponent } from './products/gds2-mesh/gds2-mesh.component';
import { GeniusComponent } from './products/genius/genius.component';
import { VisualTCADComponent } from './products/visual-tcad/visual-tcad.component';
import { VisualFabComponent } from './products/visual-fab/visual-fab.component';
import { GenesComponent } from './products/genes/genes.component';
import { BasicIpComponent } from './products/basic-ip/basic-ip.component';
import { TechnologyDevelopmentComponent } from './products/technology-development/technology-development.component';
import { MoyeeComponent } from './products/moyee/moyee.component';
import { MovisComponent } from './products/movis/movis.component';
import { MozenComponent } from './products/mozen/mozen.component';
import { MoverComponent } from './products/mover/mover.component';
import { CampusRecruitComponent } from './campus-recruit/campus-recruit.component';
import { CampusQaComponent } from './campus-qa/campus-qa.component';
import { LifeComponent } from './life/life.component';

const routes: Routes = [
  { path:'', redirectTo:'/company',pathMatch:'full' },
  { path:'home', component: HomeComponent },
  { path:'company', component: CompanyComponent },
  { path:'contact', component: ContactComponent },
  { path:'product', component: ProductsListComponent },
  { path:'product/tcad', component: ProductsListComponent },
  { path:'product/opc', component: ProductsListComponent },
  { path:'product/basic-ip', component: ProductsListComponent },
  { path:'product/technology-development', component: ProductsListComponent },
  { path:'product/gds2mesh', component: Gds2MeshComponent },
  { path:'product/genius', component: GeniusComponent },
  { path:'product/visual-tcad', component: VisualTCADComponent },
  { path:'product/visual-fab', component: VisualFabComponent },
  { path:'product/genes', component: GenesComponent },
  { path:'product/moyee', component: MoyeeComponent },
  { path:'product/movis', component: MovisComponent },
  { path:'product/mozen', component: MozenComponent },
  { path:'product/mover', component: MoverComponent },
  { path:'news', component: NewsListComponent },
  { path:'news/news-detail', component: NewsDetailComponent },
  { path:'jobs', component: JobsListComponent },
  { path:'jobs/suzhou', component: JobsListComponent },
  { path:'jobs/shanghai', component: JobsListComponent },
  { path:'jobs/xian', component: JobsListComponent },
  { path:'jobs/job-detail', component: JobDetailComponent },
  { path:'campus', component: CampusRecruitComponent },
  { path:'campus/qa', component: CampusQaComponent },
  { path:'life', component: LifeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
