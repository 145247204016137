<div class="banner job-detail-banner"></div>
<div class="container">
  <div class="jobs-item">
    <div class="job-title">
      <h3>
        <span class="job-name">{{job?.info.name}}</span>
      </h3>
      <span class="job-salary">{{job?.info.salary}}</span>
    </div>
    <div class="job-info">
      <span>{{job?.info.city}}-{{job?.info.area}}</span>
      <span>{{job?.info.experience}}年经验</span>
      <span>{{job?.info.education}}</span>
      <!-- <span>招{{job?.info.number}}人</span>
      <span>{{job?.info.time}}发布</span> -->
      <a href="https://jobs.51job.com/all/coAmZUNlEyDjQAYAxrUjY.html?timestamp__1258=n4Rx27GQgxuDnDBqDTnDUxxjOInmd4%2BKx&alichlgref=https%3A%2F%2Fwe.51job.com%2F">我要投递</a>
    </div>
  </div>
  <div class="job-description">
    <h3>职位描述</h3>
    <ol>
      <li *ngFor="let des of job?.description">
        {{des.listContent ? des.listText : des}}
        <ul *ngIf="des.listContent">
          <li *ngFor="let deslist of des.listContent">- {{deslist}}</li>
        </ul>
      </li>
    </ol>
  </div>
  <div class="job-require">
    <h3>任职要求</h3>
    <ol>
      <li *ngFor="let req of job?.require">
        {{req.listContent ? req.listText : req}}
        <ul *ngIf="req.listContent">
          <li *ngFor="let reqlist of req.listContent">- {{reqlist}}</li>
        </ul>
      </li>
    </ol>
  </div>
  <div class="job-contact">
    <h3>联系方式</h3>
    <p>联系电话： {{job?.contact.tel}}</p>
    <p>联系人： {{job?.contact.hr}}</p>
    <p>邮箱： {{job?.contact.email}}</p>
    <p>联系地址： {{job?.contact.address}}</p>
    <a *ngIf="job?.info.city == '苏州'"
    href="https://map.baidu.com/mobile/webapp/search/search/qt=s&da_src=shareurl&wd=%E8%8B%8F%E5%B7%9E%E7%8F%82%E6%99%B6%E8%BE%BE%E7%94%B5%E5%AD%90%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&c=224&src=0&wd2=%E8%8B%8F%E5%B7%9E%E5%B8%82%E8%8B%8F%E5%B7%9E%E5%B7%A5%E4%B8%9A%E5%9B%AD%E5%8C%BA&pn=0&sug=1&l=13&b=(13406533%2C3636083%3B13465829%2C3667475)&from=webmap&biz_forward=%7B%22scaler%22%3A1%2C%22styles%22%3A%22pl%22%7D&sug_forward=3d44fb27aec09b5a17c8f664&seckey=6efce2da22fe8e540c575154f379d0d365a8f02b9468e06acecbc84b2819c008c8756cfb9c0bbfedac52f7aa7c8c791b40b34e4ab606e4dfbffdf734576ba956039437805bc40c64310e8f9dc4d17d0e7a24d9bafdd433d32354ff6d7ac1616e5d7c91a7bcae2b1773a91cd6b74bfc8bb1afe152df7cd7aaaf7c4534ebbbab5499a5e00bbcb63c0e1798780d476d877931782049522e288ec6b84cbcb1971b206e8962ae464042d80d1f11366d047a02edf2b5d752ad2dfb6fe6ad234dbceedc5f9c97c1647b04bb38e53727cb663b80779edaea3b4ce2eac88a3b7bf01ed4af&device_ratio=1&=undefined/vt=map">
      <img src="assets/img/address1.png" alt="">
    </a>
    <a *ngIf="job?.info.city == '西安'"
    href="https://map.baidu.com/mobile/webapp/search/search/qt=s&da_src=shareurl&wd=%E7%A0%94%E7%A5%A5%E5%9F%8E%E5%B8%82%E5%B9%BF%E5%9C%BA-B%E5%BA%A7&c=224&src=0&wd2=%E8%A5%BF%E5%AE%89%E5%B8%82%E9%9B%81%E5%A1%94%E5%8C%BA&pn=0&sug=1&l=13&b=(13406533%2C3636083%3B13465829%2C3667475)&from=webmap&biz_forward=%7B%22scaler%22%3A1%2C%22styles%22%3A%22pl%22%7D&sug_forward=6ed503893fb7b97890114c35&seckey=ecb95474fcc56a23accff5197ee1ea3c9c14b8511b098ba24a60cedefe8ab35e2d820f7a816b87d3e800c12de558eaa9e1fcc8863867ceca72e4b76e19ba35983c057d847863955230f07d531ed267749e6a3b84f47ea44d8b66be580356e1441a2f08b3af1360963f92fffaf78820692019481e0bfd915e17d2faec320753fd65096174da4458eb155330519e31cee11e43ff31f3942b8ca73e75238feef890e42e601ec8588736174761ba42118556c6e213749a0e0e6a838a01ba93e167eb5a1d1d7f73505f669be5f0bc9c410056ffe6a555b9a3805b5d392e6f7d8ddeab&device_ratio=1&=undefined/vt=map">
      <img src="assets/img/address2.png" alt="">
    </a>
    <a *ngIf="job?.info.city == '上海'"
    href="https://map.baidu.com/mobile/webapp/search/search/qt=s&da_src=shareurl&wd=%E5%BC%A0%E6%B1%9F%E7%94%B5%E5%AD%90%E6%B8%AF-5%E5%8F%B7%E6%A5%BC&c=233&src=0&wd2=%E4%B8%8A%E6%B5%B7%E5%B8%82%E6%B5%A6%E4%B8%9C%E6%96%B0%E5%8C%BA&pn=0&sug=1&l=18&b=(12119227.83%2C4030451.07%3B12121080.83%2C4031432.07)&from=webmap&biz_forward=%7B%22scaler%22%3A1%2C%22styles%22%3A%22pl%22%7D&sug_forward=2bd6f34db61899d186420e5d&seckey=ecb95474fcc56a23accff5197ee1ea3c9c14b8511b098ba24a60cedefe8ab35e2d820f7a816b87d3e800c12de558eaa9e1fcc8863867ceca72e4b76e19ba35983c057d847863955230f07d531ed267749e6a3b84f47ea44d8b66be580356e1441a2f08b3af1360963f92fffaf78820692019481e0bfd915e17d2faec320753fd65096174da4458eb155330519e31cee11e43ff31f3942b8ca73e75238feef890e42e601ec8588736174761ba42118556c6e213749a0e0e6a838a01ba93e167eb5a1d1d7f73505f669be5f0bc9c410056ffe6a555b9a3805b5d392e6f7d8ddeab&device_ratio=1&=undefined/vt=map">
      <img src="assets/img/address3.png" alt="">
    </a>
  </div>
</div>
