<div class="banner qa-banner"></div>
<div class="container qa">
  <h2 *ngIf="curQA == 'star'">明日之星计划</h2>
  <h2 *ngIf="curQA == 'recruit'">校园招聘</h2>
  <h2 *ngIf="curQA == 'hire'">录用和实习</h2>

  <div *ngFor="let item of qa" class="qa-item">
    <div class="qa-q">
      <span>Q</span>
      <p>{{item.q}}</p>
    </div>
    <div class="qa-a">
      <span>A</span>
      <p>{{item.a}}</p>
    </div>
  </div>
</div>
