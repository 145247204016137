import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gds2-mesh',
  templateUrl: './gds2-mesh.component.html',
  styleUrls: ['./../product.component.css']
})
export class Gds2MeshComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
