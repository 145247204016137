<div class="banner product-banner product3">
  <span class="red-span">GDs2Mesh</span>
  <p>三维 TCAD 模型构建工具</p>
</div>
<div class="container">
  <h3>产品介绍</h3>
  <p>Gds2Mesh 是一个三维 TCAD 模型构建工具，它可以导入 GDSII 版图，然后结合预定义、可定制的工艺规则来生成三维</p>
  <div class="img-container">
    <img src="assets/img/products/gds2mesh1.png" alt="">
    <p>0.13um CMOS 与非门标准单元版图 (左图）</p>
    <p>Gds2Mesh 生成的 TCAD 模型 (右图）</p>
  </div>
  <h3>功能如下：</h3>
  <ul class="ul-list">
    <li><span>内嵌三维 CSG 引擎</span></li>
    <li><span>版图二维多边形拉伸生成三维结构</span></li>
    <li><span>GDSII 文件二维多边形图形提取</span></li>
    <li><span>脚本生成、调整多边形图形</span></li>
    <li><span>二维图形和三维体的布尔操作</span></li>
    <li><span>脚本定制的工艺规则</span></li>
    <li><span>Python 编程接口</span></li>
    <li><span>掩模板定义掺杂分布区域</span></li>
    <li><span>高质量四面体网格</span></li>
    <li><span>常用数据格式网格信息导出</span></li>
  </ul>
  <div class="img-container">
    <img src="assets/img/products/gds2mesh2.png" alt="">
  </div>
  <div class="img-container">
    <img src="assets/img/products/gds2mesh3.png" alt="">
    <p>Gds2Mesh 生成的 TCAD 模型</p>
  </div>
  <p>0.13um 工艺规则文件首先从版图中提取各个层次的几何信息，然后通过拉伸以及其他的几何操作，来构造器件的三维几何体。掺杂分布的区域也需要基于版图信息来确定。</p>
  <p>CSG 引擎可以生成倾斜的 STI 侧墙、圆滑的 STI 边角、鸟嘴、以及其它精细结构。网格是自动生成的，沟道、结附近的网格是细化的以保证仿真的准确性。生成的器件结构可以直接导入 Genius 执行器件仿真。</p>
  <h3>生成 GDML 文件</h3>
  <p>Gds2Mesh 还可以生成 GDML 格式的文件，然后导入VisualParticle/GSeat执行粒子轨迹仿真。GDML 是由Geant4定义的一种标准几何格式。</p>
</div>
