<div class="banner product-banner product2">
  <span class="red-span">VisualTCAD</span>
  <p>器件仿真图形用户界面</p>
</div>
<div class="container">
  <h3>产品介绍</h3>
  <p>VisualTCAD 是器件仿真软件 Genius 的图形化用户界面，致力于提高易用性，以满足初级 TCAD 用户和学生的需求。使用 TCAD 工具从来没有如此简单，复杂的命令行或编写代码从此不再需要，初学者数分钟内就可以掌握 VisualTCAD 然后开始 Genius 仿真工作。另一方面，使用 VisualTCAD 不会导致 Genius 仿真功能的损失，所有 的物理模型及选项都可以通过界面选择和填加。</p>
  <p>VisualTCAD 能够执行二维和三维器件仿真、SPICE 电路仿真，以及电路/器件混合仿真，其由以下模块组成</p>
  <ul class="ul-list">
    <li><span>器件结构描绘工具</span></li>
    <li><span>电路原理图捕获工具</span></li>
    <li><span>仿真控制模块</span></li>
    <li><span>仿真结果可视化工具</span></li>
    <li><span>电子数据表</span></li>
    <li><span>X-Y 绘图工具</span></li>
  </ul>
  <p>VisualTCAD 功能可以通过一个 <a href="http://cogenda.com/article/Tutorial-VisualTCAD1:wide">10分钟视频演示</a> 作快速了解， 试用版 VisualTCAD 可以通过 <a href="http://cogenda.com/article/download">此处下载</a> 。 一些软件运行模块截图如下所示。</p>
  <div class="img-container">
    <img src="assets/img/products/tcad1.jpg" alt="">
    <p>器件结构显示模块</p>
  </div>
  <div class="img-container">
    <img src="assets/img/products/tcad2.jpg" alt="">
    <p>仿真控制模块</p>
  </div>
  <div class="img-container">
    <img src="assets/img/products/tcad3.jpg" alt="">
    <p>电路仿真模块</p>
  </div>
</div>
