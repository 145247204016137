<div class="banner contact-banner"
  [ngClass]="{'address-bg':curNav == 'address','dealers-bg':curNav == 'dealers'}">
  <span class="red-span">{{curNav == 'address' ? 'Contact us' : 'Authorized distributor '}}</span>
</div>
<div class="nav">
  <div class="nav-item" [ngClass]="{'nav-highlight':curNav == 'address'}" (click)="changeNav('address')">联系地址</div>
  <div class="nav-item" [ngClass]="{'nav-highlight':curNav == 'dealers'}" (click)="changeNav('dealers')">授权分销商</div>
</div>

<div class="container">
  <ngCantainer *ngIf="curNav == 'address'">
    <div class="company-item">
      <a href="https://map.baidu.com/mobile/webapp/search/search/qt=s&da_src=shareurl&wd=%E8%8B%8F%E5%B7%9E%E7%8F%82%E6%99%B6%E8%BE%BE%E7%94%B5%E5%AD%90%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&c=224&src=0&wd2=%E8%8B%8F%E5%B7%9E%E5%B8%82%E8%8B%8F%E5%B7%9E%E5%B7%A5%E4%B8%9A%E5%9B%AD%E5%8C%BA&pn=0&sug=1&l=17&b=(13431740.53656465%2C3647497.9475527%3B13436726.53656465%2C3650177.9475527)&from=webmap&biz_forward=%7B%22scaler%22%3A1%2C%22styles%22%3A%22pl%22%7D&sug_forward=b4d1efebfb1c0e3b12882402&device_ratio=1&=undefined/vt=map">
        <img src="assets/img/address1.png" alt="">
      </a>
      <div class="info">
        <h3>苏州总部</h3>
        <p class="p-address">江苏省苏州市工业园区国际科技园一期143室</p>
        <p class="p-postal">邮编：215021</p>
        <p class="p-tel">电话(传真)： +86 512 67900636</p>
        <p class="p-address">Room 143, Phase I, International Science park, Suzhou, Jiangsu, China,</p>
        <p class="p-postal">Postal Code: 215021</p>
        <p class="p-tel">Tel(Fax): +86 512 67900636</p>
      </div>
    </div>
    <div class="company-item">
      <a href="https://map.baidu.com/mobile/webapp/search/search/qt=s&da_src=shareurl&wd=%E7%A0%94%E7%A5%A5%E5%9F%8E%E5%B8%82%E5%B9%BF%E5%9C%BA-B%E5%BA%A7&c=224&src=0&wd2=%E8%A5%BF%E5%AE%89%E5%B8%82%E9%9B%81%E5%A1%94%E5%8C%BA&pn=0&sug=1&l=13&b=(13406533%2C3636083%3B13465829%2C3667475)&from=webmap&biz_forward=%7B%22scaler%22%3A1%2C%22styles%22%3A%22pl%22%7D&sug_forward=6ed503893fb7b97890114c35&seckey=ecb95474fcc56a23accff5197ee1ea3c9c14b8511b098ba24a60cedefe8ab35e2d820f7a816b87d3e800c12de558eaa9e1fcc8863867ceca72e4b76e19ba35983c057d847863955230f07d531ed267749e6a3b84f47ea44d8b66be580356e1441a2f08b3af1360963f92fffaf78820692019481e0bfd915e17d2faec320753fd65096174da4458eb155330519e31cee11e43ff31f3942b8ca73e75238feef890e42e601ec8588736174761ba42118556c6e213749a0e0e6a838a01ba93e167eb5a1d1d7f73505f669be5f0bc9c410056ffe6a555b9a3805b5d392e6f7d8ddeab&device_ratio=1&=undefined/vt=map">
        <img src="assets/img/address2.png" alt="">
      </a>
      <div class="info">
        <h3>西安分部</h3>
        <p class="p-address">陕西省西安市高新区锦业一路研祥城市广场B302室</p>
        <p class="p-postal">邮编：710065</p>
        <p class="p-tel">电话(传真)： +86 029 81132514</p>
        <p class="p-address">Room B302, Yanxiang City Plaza, Jinye First Road, High-tech Zone, Xi 'an, Shaanxi, China,</p>
        <p class="p-postal">Postal Code: 710065</p>
        <p class="p-tel">Tel(Fax): +86 029 81132514</p>
      </div>
    </div>
    <div class="company-item">
      <a href="https://map.baidu.com/mobile/webapp/search/search/qt=s&da_src=shareurl&wd=%E5%BC%A0%E6%B1%9F%E5%BE%AE%E7%94%B5%E5%AD%90%E6%B8%AF-1%E5%8F%B7%E6%A5%BC&c=289&src=0&pn=0&sug=0&l=19&b=(13534838.475%2C3636757.835%3B13536084.975%2C3637427.835)&from=webmap&biz_forward=%7B%22scaler%22%3A1%2C%22styles%22%3A%22pl%22%7D&device_ratio=1&=undefined/vt=map">
        <img src="assets/img/address3.png" alt="">
      </a>
      <div class="info">
        <h3>上海分部</h3>
        <p class="p-address">上海市浦东新区碧波路690号张江微电子港1号楼406室</p>
        <p class="p-postal">邮编：201203</p>
        <p class="p-tel">电话(传真)： +86 512 67900636</p>
        <p class="p-address">Room 406, Building 1, Zhangjiang Microelectronics Port, 690 Bibo Road, Pudong New Area, Shanghai, China,</p>
        <p class="p-postal">Postal Code: 201203</p>
        <p class="p-tel">Tel(Fax): +86 512 67900636</p>
      </div>
    </div>
    <div class="company-item">
      <div class="info">
        <h3>新加坡办事处</h3>
        <p class="p-address">Cogenda Pte Ltd 100 Tras Street #16-01 Amara Corporate Tower</p>
        <p class="p-postal">Singapore 079027</p>
        <p class="p-tel">Tel.: +65 31591041</p>
      </div>
    </div>
    <div class="company-item">
      <div class="info">
        <h3>电子邮件</h3>
        <p>技术支持： support@cogenda.com</p>
        <p>销售： sales@cogenda.com</p>
        <p>招聘： hr@cn.cogenda.com</p>
        <p>其他问询： contact@cogenda.com</p>
        <p>我们不会向您的邮箱发送广告，不会向他人透露您的联系方式或其他私人信息。</p>
      </div>
    </div>
  </ngCantainer>
  <ngCantainer *ngIf="curNav == 'dealers'">
    <div class="dealers">
      <p>您可以联系培风图南在下列地区的授权代理分销商：</p>
      <h3>中国大陆</h3>
      <h5>北京耀华创芯电子科技有限公司</h5>
      <p>北京市海淀区长春桥路11号万柳亿城大厦C1座301室,100089</p>
      <p>Beijing U-Creative Technology Co., Ltd</p>
      <p>11 Changchun Qiao Road, #C1-301, Wan Liu Yi Cheng Tower, Haidian, Beijing</p>
      <p>Tel: +86-10-58819138/39</p>
      <p>Fax: +86-10-58819137</p>
      <p>Email: sales@u-c.com.cn</p>
      <p><a href="http://www.u-c.com.cn">http://www.u-c.com.cn</a></p>

      <h3>台湾、香港、澳门地区</h3>
      <p>Microport Computer Electronics Inc.</p>
      <p>Building A, No.143, Ln. 152, Sec. 1, Baoda Rd., Guiren Dist. Tainan City, Taiwan, R.O.C. 71141</p>
      <p>TEL: +886-6-330-3000 (Rep.)</p>
      <p>Fax: +886-6-330-2526</p>
      <p>台南市歸仁區保大路一段152巷143號A棟</p>
      <p><a href="http://www.microport.com.tw">http://www.microport.com.tw</a></p>

      <h3>日本</h3>
      <p>I-Vis Inc.</p>
      <p>Salute Bldg. 8F 72 Yoshidamachi Nakaku Yokohama 231-0041 Japan</p>
      <p>231-0041横浜市中区吉田町72　サリュートビル8F</p>
      <p>Tel: +81-45-251-3118</p>
      <p>Fax: +81-45-251-3117</p>
      <p><a href="http://www.i-vis.co.jp">http://www.i-vis.co.jp</a></p>

      <h3>印度</h3>
      <p>Cadre Design Systems</p>
      <p>828 New Avas Vikas, Saharanpur, Pin-247001, (UP) India</p>
      <p>and</p>
      <p>Unit 611, Aditya High Street NH-24, Plot No. 1/2, SSGT Road, NH24, Ghaziabad, (UP) India</p>
      <p>Tel: +91-9999686355</p>
      <p>Tel.: +91-9311290546</p>
      <p>Email: sales@cadredesign.co.in</p>
      <p><a href="http://www.cadredesign.co.in">http://www.cadredesign.co.in</a></p>

      <h3>马来西亚</h3>
      <p>Ramada Synergy Sdn Bhd</p>
      <p>Blok D1, UKM-MTDC Technology Centre, 43600 UKM Bangi, Selangor Darul Ehsan, Malaysia.</p>
      <p>Tel.: +603 8920 8550</p>
      <p>Email: luqman@ramadasynergy.com</p>
      <p><a href="http://www.ramadasynergy.com">http://www.ramadasynergy.com</a></p>

      <h3>韩国</h3>
      <p>3F, 173, Nonhyeon-ro, Seocho-gu, Seoul, Republic of Korea</p>
      <p>Tel:  +82-70-5138-0700</p>
      <p>Email: </p>
      <p>EDA Solution: eda@linkglobal21.com</p>
      <p>OPTIC Solution: disp@linkglobal21.com</p>
      <p>OPTIC Instrument: infour@linkglobal21.com</p>
    </div>
  </ngCantainer>
</div>
