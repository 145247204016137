<div class="banner product-banner product3">
  <span class="red-span">MoVis</span>
</div>
<div class="container">
  <h3>产品介绍</h3>
  <p>MoVis在版图处理的基础上，集成了光刻仿真的可视化功能。用户可通过MoVis对版图文件进行查看、测量、修改、保存，还可解析MoYee生成的边准模型文件，计算、查看指定位置的光强。</p>
  <div class="img-container">
    <img src="assets/img/products/movis1.png" alt="">
    <p>多层光刻效果综合图</p>
  </div>

  <h3>功能说明</h3>
  <ul class="ul-list">
    <li><span>版图处理功能</span></li>
    <li><span>解析MoYee的标准模型文件</span></li>
    <li><span>多方法计算光强</span></li>
    <li><span>查看指定位置的光强</span></li>
    <li><span>查看指定区域的光刻仿真结果</span></li>
    <li><span>标准文件与版图互动</span></li>
  </ul>
  <p>全芯片版图具有丰富的图形信息和复杂的层次结构，MoVis支持多种计算方法，快速响应光强计算过程，便于用户进行模型验证和仿真检查。</p>
</div>
