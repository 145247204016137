import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import jobsData from "./../../assets/data/jobs.json";

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.css']
})
export class JobsListComponent implements OnInit {
  jobs:any;
  pointPosition:any;
  curNav:string = '苏州';
  bannerHeight:string;
  searchValue:string;
  showEmpty:boolean = false;

  constructor(
    private router: Router,
  ) {
    const self = this;
    self.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd && event.url) {
        if(event.url.includes('suzhou')){
          self.curNav = '苏州';
        }else if(event.url.includes('shanghai')){
          self.curNav = '上海';
        }else if(event.url.includes('xian')){
          self.curNav = '西安';
        }else{
          self.curNav = '苏州';
        }
        self.jobs = jobsData;
      }
    });
  }

  ngOnInit(): void {

  }

  changeNav(nav:string): void {
    const self = this;
    self.curNav = nav;

    let curNavJobs = self.jobs.filter(item => item.info.city.includes(nav));
    curNavJobs.length ? self.showEmpty = false : self.showEmpty = true;
  }

  goToRouter(job:any): void {
    const self = this;
    sessionStorage.setItem('job',JSON.stringify(job));
    self.router.navigate(["/jobs/job-detail"]);
  }

  search(event:any): void {
    const self = this;
    if(event.keyCode === 13 || event.type === 'click'){
      console.log(self.searchValue);
      if(self.searchValue){
        let searchjobs = jobsData.filter((item) => {
          const name = item.info.name.toLocaleLowerCase();
          const value = self.searchValue.toLocaleLowerCase();
          return name.includes(value);
        });
        self.jobs = searchjobs;
        searchjobs.length ? self.showEmpty = false : self.showEmpty = true;
      }else{
        self.jobs = jobsData;
        self.showEmpty = false;
      }
    }
  }

}
