import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import campusJobsData from "./../../assets/data/campus-jobs.json";

@Component({
  selector: 'app-campus-recruit',
  templateUrl: './campus-recruit.component.html',
  styleUrls: ['./campus-recruit.component.css']
})
export class CampusRecruitComponent implements OnInit {
  nav:any;
  curNav:number = 0;
  jobs:any;
  curTypeNav:string = '数理基础';
  showEmpty:boolean = false;

  constructor(private router: Router) {
    const self = this;
    self.jobs = campusJobsData;
    self.nav = ['明日之星计划','招聘岗位','Q&A','学长学姐说'];
    self.curNav = 0;
  }

  ngOnInit(): void {
  }

  @HostListener('window:scroll',['$event'])
  onWindowResize(event){
    const self = this;
    let top = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;

    let doc1:any = document.querySelector('#title1');
    if(top < doc1.offsetTop - 115 - 300){
      self.curNav = 0;
    }else{
      let doc2:any = document.querySelector('#title2');
      if(top < doc2.offsetTop - 115 - 300){
        self.curNav = 1;
      }else if(top < doc2.offsetTop + 100){
        self.curNav = 2;
      }else{
        self.curNav = 3;
      }
    }

    let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
    if(top == scrollHeight - clientHeight){
      self.curNav = 3;
    }
  }

  goToRouter(curQA:string): void {
    const self = this;
    sessionStorage.setItem('curQA',curQA);
    self.router.navigate(['/campus/qa']);
  }

  goToTD(event:any): void {
    event.stopPropagation();
    window.location.href="https://www.wjx.cn/vm/tH6zy5j.aspx";
  }

  changeNav(nav:string): void {
    const self = this;
    self.curTypeNav = nav;

    let curNavJobs = self.jobs.filter(item => item.info.type.includes(nav));
    curNavJobs.length ? self.showEmpty = false : self.showEmpty = true;
  }

  navClick(index:number): void {
    const self = this;
    let doc:any = document.querySelector('#title'+index);
    window.scrollTo(0,doc.offsetTop-115);
    self.curNav = index;
  }

}
