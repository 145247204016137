<div class="banner product-banner product2">
  <span class="red-span">MoVer</span>
</div>
<div class="container">
  <h3>产品介绍</h3>
  <p>快速计仿真出版图在不同工艺条件下的光刻效果，分析关键点的位置，判断出不可靠区域。</p>
  <div class="img-container img-60">
    <img src="assets/img/products/mover.png" alt="">
    <p>电镜图和方针图，带PW</p>
  </div>

  <h3>功能说明</h3>
  <ul class="ul-list">
    <li><span>Python编程接口</span></li>
    <li><span>判断出常见的违例（bridge & pinch）</span></li>
    <li><span>综合考虑不同工艺条件</span></li>
    <li><span>输出问题点坐标</span></li>
    <li><span>输出分析报告</span></li>
  </ul>

  <p>MoVer 是对当前 OPC 结果的检查与初步验证，决定了当前 OPC 是否达到预期标准。针对全芯片进行检查该步骤对光强计算的精度和速度要求比较高。</p>
</div>
