import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-moyee',
  templateUrl: './moyee.component.html',
  styleUrls: ['./../product.component.css']
})
export class MoyeeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
