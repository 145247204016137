<div class="banner product-banner product5">
  <span class="red-span">MoZen</span>
</div>
<div class="container">
  <h3>MoZen</h3>
  <h4 class="color-red">产品介绍</h4>
  <p>经过光刻系统变换处理，硅片表面的图形信息和mask版上的信息必然会不同。建模获得了准确的光刻行为，以此为基础，对设计出的版图进行计算修正。将修正后的mask用于光刻生产，硅片上得到的图形会更符合设计预期。</p>
  <div class="img-container">
    <img src="assets/img/products/mozee1.png" alt="">
    <p>已修版图和未修版图的光刻结果对比图</p>
  </div>
  <p>MoZen提供丰富的图形处理函数，并能结合准确的模型，准确地、高效地实现修正过程。</p>

  <!-- <h3>功能说明</h3> -->
  <h4 class="color-red">功能说明</h4>
  <ul class="ul-list">
    <li><span>Python编程接口</span></li>
    <li><span>图层的逻辑操作</span></li>
    <li><span>丰富的图形操作</span></li>
    <li><span>设计图形的目标点重定义</span></li>
    <li><span>版图层次化处理</span></li>
    <li><span>标准化分析报告</span></li>
    <li><span>任务并行计算</span></li>
    <li><span>GPU加速</span></li>
  </ul>

  <div class="img-container">
    <img src="assets/img/products/mozee2.png" alt="">
    <p>修正效果图</p>
  </div>

  <div class="img-container">
    <img src="assets/img/products/mozee3.png" alt="">
    <p>MoZen界面预览</p>
  </div>

  <p>MoZen支持多种优化方法，能快速确定目标点的位移量。MoZen还融入了多线程并行计算和GPU加速技术能极大地缩短对大规模版图修正时间。</p>

  <h3>MoZen-PV</h3>
  <h4 class="color-red">产品介绍</h4>
  <p>MoZen-PV是功能卓越的光刻仿真和验证软件。能快速计算整个芯片在不同工艺条件下的光刻效果，分析关键点的位置，判断出不可靠区域。</p>
  <div class="img-container">
    <img src="assets/img/products/mover.png" alt="">
    <p>光刻PV图</p>
  </div>

  <!-- <h3>功能说明</h3> -->
  <h4 class="color-red">功能说明</h4>
  <ul class="ul-list">
    <li><span>Python编程接口</span></li>
    <li><span>判断出常见的违例</span></li>
    <li><span>综合考虑不同工艺条件</span></li>
    <li><span>标准化的分析报告</span></li>
  </ul>

  <p>MoZen-PV是对当前OPC结果的检查与初步验证，帮助用户判断当前OPC结果是否达到预期标准。图形化的分析验证工具在整个工作中非常重要。</p>
</div>
