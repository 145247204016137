<div class="container">
  <img [src]="'assets/img/news/' + news.bannerImg" alt="">
  <h3>{{news.title}}</h3>
  <ngContainer *ngFor="let key of contentTags">
    <p class="text-indent" *ngIf="key.includes('p')">{{news.content[key]}}</p>
    <div class="img-container" *ngIf="key.includes('img')">
      <img [src]="'assets/img/news/' + news.content[key]" alt="">
    </div>
  </ngContainer>
</div>
