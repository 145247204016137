<div class="banner product-banner product2">
  <span class="red-span">MoYee</span>
  <p>光强计算处理引擎</p>
</div>
<div class="container">
  <h3>产品介绍</h3>
  <p>Moyee提供了一个综合性极高的光强计算处理引擎，可高效地为各类版图计算光刻仿真结果。能全面地考虑光源、光瞳、掩模、光刻胶等对硅表面光强的影响。</p>
  <div class="img-container">
    <img src="assets/img/products/moyee1.png" alt="">
    <p>光刻系统简图</p>
  </div>

  <h3>功能说明</h3>
  <ul class="ul-list">
    <li><span>Python编程接口</span></li>
    <li><span>模块化建模</span></li>
    <li><span>支持多种模型</span></li>
    <li>
      <span>支持多种掩膜处理</span>
      <ul class="ul-list">
        <li><span>Mask loading effect</span></li>
        <li><span>Mask corner rounding</span></li>
        <li><span>Mask 3D effect</span></li>
      </ul>
    </li>
    <li><span>支持考虑光刻胶的影响</span></li>
    <li><span>支持工艺窗口分析</span></li>
    <li><span>支持多种优化算法</span></li>
    <li><span>标准模型文件导出</span></li>
  </ul>

  <div class="img-container">
    <img src="assets/img/products/moyee2.png" alt="">
    <p>建模示意图</p>
  </div>

  <div class="img-container">
    <img src="assets/img/products/moyee3.png" alt="">
    <p>MoYee界面预览</p>
  </div>

  <p>随着光刻技术的不断提升，光刻软件也需要不断提升性能。在功能模块上，MoYee综合考虑mask3D和resist效应，建立更加复杂的模型基础。在计算性能上，MoYee采用共享内存技术，减少计算资源重复消耗。目前完全能支持40nm及以上工艺节点的计算需求。</p>
  <p>MoYee创建的可靠光刻系统模型，将为版图修正提供有效依据，为芯片制造良率提高提供保证。</p>
</div>
