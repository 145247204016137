import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import newsData from "./../../assets/data/news.json";

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})
export class NewsListComponent implements OnInit {
  news:any;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    const self = this;
    self.news = newsData;
  }

  goToRouter(curNews): void {
    const self = this;
    sessionStorage.setItem('news',JSON.stringify(curNews));
    self.router.navigate(["/news/news-detail"]);
  }

}
