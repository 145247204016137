<div class="banner product-banner product1">
  <span class="red-span">Genius</span>
  <p>三维并行器件仿真软件</p>
</div>
<div class="container">
  <h3>产品介绍</h3>
  <p>传统的 TCAD 器件仿真时间周期过长，效率低下，一直是很多用户的困扰。新一代三维并行器件仿真软件，Genius，可以帮助客户完美的解决这些问题。Genius 是培风图南引以为豪的产品，这款软件内嵌先进的并行计算技术，使得仿真规模大，效率高。Genius 是跨越 10-晶体管壁垒 的商业 TCAD 仿真软件，它使得对一些电路单元的仿真变得可能，比如反相器、6管 SRAM、锁存器和触发器，并且仿真时间可以降低一个数量级，极大的提高了仿真效率。</p>
  <p>Genius 采用全新的设计方法，在这点上超越目前市场上的其他 TCAD 软件。它具有并行、兼容性、可扩展性的特点，这些先进的理念也是软件设计的初衷。除此之外，Genius 程序模块的搭建还采用了先进的数值模拟技术和软件开发平台。</p>
  <p>Genius 的并行计算方法可以大幅度减少仿真时间，从时间周期上允许对超大的单个器件、或者包含数个器件的电路模块进行仿真，消除使用其他的 TCAD 软件处理这些问题时遇到的的困难。</p>
  <div class="img-container">
    <img src="assets/img/products/genius1.jpg" alt="">
    <img src="assets/img/products/genius2.jpg" alt="">
    <p>Genius 三维 CMOS 反相器仿真时间及并行加速。</p>
  </div>
  <h3>主要特点</h3>
  <ul class="ul-list">
    <li><span>漂移-扩散模型</span></li>
    <li><span>晶格热模型</span></li>
    <li><span>能量平衡模型</span></li>
    <li><span>直流、交流和瞬时仿真模型</span></li>
    <li><span>电路/器件混合仿真模型</span></li>
    <li><span>多达 30 多种材料的材料库</span></li>
    <li><span>宽范围载流子模型</span></li>
    <li><span>冲击离化模型</span></li>
    <li><span>带-带隧穿模型</span></li>
    <li><span>载流子缺陷捕获模型</span></li>
    <li><span>霍尔效应</span></li>
    <li><span>三维光线追踪算法</span></li>
    <li><span>二维有限元光线算法</span></li>
    <li><span>三维总剂量效应模型</span></li>
    <li><span>基于 GDSII 版图的器件模型构建</span></li>
    <li><span>可与其他主流 TCAD 仿真数据交互</span></li>
  </ul>
  <h3>举例：某六管 SRAM</h3>
  <h4>建模和网格：</h4>
  <div class="img-container">
    <img src="assets/img/products/genius3.jpg" alt="">
    <p>某六管 SRAM 的建模和网格</p>
  </div>
  <h4>仿真结果（某时刻的电势分布）：</h4>
  <div class="img-container">
    <img src="assets/img/products/genius4.jpg" alt="">
    <p>某六管 SRAM 的仿真结果（某时刻的电势分布）</p>
  </div>
</div>
