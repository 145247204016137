<h3>服务概览</h3>
<p>提供180nm 到 28nm等工艺节点下的通用基础IP，包括体硅、PDSOI、FDSOI等多种工艺类型。IP类型包括</p>
<ul class="ul-list">
  <li><span>标准单元库，包括DICE触发器</span></li>
  <li><span>通用IO库</span></li>
  <li><span>SRAM/ROM存储器编译器</span></li>
  <li><span>eFuse/OTP存储器</span></li>
</ul>
<p>同时，我们在上述工艺平台提供各种模拟IP，包括</p>
<ul class="ul-list">
  <li><span>LDO片上电源</span></li>
  <li><span>PLL时钟发生器</span></li>
  <li><span>ADC模数转换器</span></li>
  <li><span>DAC数模转换器</span></li>
</ul>

<h3>宇航级抗辐射加固IP</h3>
<p>提供上述IP的宇航级抗辐射加固版本。在设计过程中充分利用了TCAD仿真技术，对上述IP进行了全面的辐射效应优化和验证。经地面试验验证，IP可以满足下列抗辐射指标：</p>
<ul class="ul-list">
  <li><span>耐受总剂量 500kRad(Si)</span></li>
  <li><span>单粒子闩锁免疫，SEL阈值 LET<span class="text-down">th</span> > 99 MeV/mg/cm<span class="text-up">2</span></span></li>
  <li><span>DICE加固存储单元的 SEU阈值 LET<span class="text-down">th</span> > 99 MeV/mg/cm<span class="text-up">2</span></span></li>
</ul>

<div class="img-container">
  <img src="assets/img/products/ip1.png" alt="">
</div>
