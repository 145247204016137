<div class="banner product-banner product4">
  <span class="red-span">VisualFab</span>
  <p>工艺实验管理平台</p>
</div>
<div class="container">
  <h3>产品介绍</h3>
  <p>VisualFab 是一个工艺仿真实验的综合管理平台，其设计理念来源于 Fab 工程师的工作方式，比如工艺模块、 分批、 晶圆、分批表。 VisualFab 使得用户可以通过非常友好的图形界面来检查，设计晶圆分批实验。</p>
  <div class="img-container">
    <img src="assets/img/products/fab1.jpg" alt="">
    <p>Fab 工程分批与 VisualFab 工艺实验</p>
  </div>
  <div class="img-container">
    <img src="assets/img/products/fab2.jpg" alt="">
    <p>VisualFab 工艺实验窗口</p>
  </div>
  <h3>功能如下: </h3>
  <ul class="ul-list">
    <li><span>工艺步骤可在工艺模块中编辑</span></li>
    <li><span>工艺步骤可按层次分组</span></li>
    <li><span>工艺步骤代码可由不同仿真器语言编写</span></li>
    <li><span>支持不同厂家的仿真器</span></li>
    <li><span>可基于工艺模块中的变量设计实验（比如退火时间和温度）</span></li>
    <li><span>分批表整合所有的设计变量和实验分批</span></li>
    <li><span>仿真进程排队功能</span></li>
    <li><span>无需重复已完成仿真</span></li>
    <li><span>器件结构及内部变量显示</span></li>
  </ul>
  <div class="img-container">
    <img src="assets/img/products/fab3.jpg" alt="">
    <p>一组结构的显示（图示为其中的一个LOCOS结构）</p>
  </div>
</div>
