<p-carousel [value]="senior">
  <ng-template let-item pTemplate="item">
    <div class="senior-item">
      <div class="img" [ngClass]="item.img"><span>{{item.name}} {{item.campus}}</span></div>
      <p class="text">{{item.description[0]}}</p>
      <!-- <p class="name">{{item.name}} ({{item.job}})</p> -->
      <p class="btn" (click)="showMore(item)">more</p>
    </div>
  </ng-template>
</p-carousel>

<p-dialog [header]="curSenior?.name" [modal]="true" [(visible)]="showDialog"
  [style]="{width: '70vw'}">
  <img [src]="'assets/img/'+curSenior?.img+'.png'" alt="img"
    *ngIf="showDialog">
  <p *ngFor="let content of curSenior?.description" class="dialog-text">{{content}}</p>
</p-dialog>
