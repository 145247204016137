import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CompanyComponent } from './company/company.component';
import { ContactComponent } from './contact/contact.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { JobsListComponent } from './jobs-list/jobs-list.component';
import { JobDetailComponent } from './job-detail/job-detail.component';
import { Gds2MeshComponent } from './products/gds2-mesh/gds2-mesh.component';
import { GeniusComponent } from './products/genius/genius.component';
import { VisualTCADComponent } from './products/visual-tcad/visual-tcad.component';
import { VisualFabComponent } from './products/visual-fab/visual-fab.component';
import { GenesComponent } from './products/genes/genes.component';
import { BasicIpComponent } from './products/basic-ip/basic-ip.component';
import { TechnologyDevelopmentComponent } from './products/technology-development/technology-development.component';
import { MoyeeComponent } from './products/moyee/moyee.component';
import { MovisComponent } from './products/movis/movis.component';
import { MozenComponent } from './products/mozen/mozen.component';
import { MoverComponent } from './products/mover/mover.component';
import { CampusRecruitComponent } from './campus-recruit/campus-recruit.component';
import { CampusQaComponent } from './campus-qa/campus-qa.component';
import { LifeComponent } from './life/life.component';
import { SeniorComponent } from './senior/senior.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Gds2MeshComponent,
    CompanyComponent,
    ContactComponent,
    ProductsListComponent,
    NewsListComponent,
    NewsDetailComponent,
    JobsListComponent,
    JobDetailComponent,
    GeniusComponent,
    VisualTCADComponent,
    VisualFabComponent,
    GenesComponent,
    BasicIpComponent,
    TechnologyDevelopmentComponent,
    MoyeeComponent,
    MovisComponent,
    MozenComponent,
    MoverComponent,
    CampusRecruitComponent,
    CampusQaComponent,
    LifeComponent,
    SeniorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    DialogModule,
    AccordionModule,
    ButtonModule,
    CarouselModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
