import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.css']
})
export class JobDetailComponent implements OnInit {
  job:any;

  constructor(
    private router: Router,
  ) {  }

  ngOnInit(): void {
    const self = this;
    if(sessionStorage.getItem('job')){
      self.job = JSON.parse(sessionStorage.getItem('job'));
    }else {
      self.router.navigate(["/jobs"]);
    }
  }

}
