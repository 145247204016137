<div class="banner product-banner product5">
  <span class="red-span">Genes</span>
  <p>二维工艺仿真软件</p>
</div>
<div class="container">
  <h3>产品介绍</h3>
  <p>Genes是墨研公司自主开发的二维工艺仿真软件，支持硅以及碳化硅的工艺仿真。软件包含了完整的工艺模块仿真能力，包含刻蚀，沉积，离子注入，扩散，氧化，外延生长以及硅化物生长。</p>

  <h3>底层几何库</h3>
  <ul class="ul-list">
    <li><span>高效和鲁棒的底层几何库</span></li>
    <li><span>基于精确数学计算的几何引擎</span></li>
    <li><span>支持点、直线、多边形之间的几何操作</span></li>
    <li><span>快速直线相交和多边形布尔运算</span></li>
  </ul>
  <div class="img-container">
    <img src="assets/img/products/genes1.png" alt="">
  </div>

  <h3>底层网格库</h3>
  <p>基于四叉树的高质量网格生成器</p>
  <p>四叉树作为背景网格，边界处进行特殊处理以保证网格质量</p>
  <p>优势：</p>
  <ul class="ul-list">
    <li><span>适合自适应网格优化</span></li>
    <li><span>插值友好</span></li>
  </ul>
  <div class="img-container">
    <img src="assets/img/products/genes2.png" alt="">
  </div>

  <h3>底层偏微分方程库</h3>
  <ul class="ul-list">
    <li><span>基于符号的偏微分方程求解代码自动生成</span></li>
  </ul>
  <div class="img-container">
    <img src="assets/img/products/genes3.png" alt="">
  </div>

  <h3>刻蚀</h3>
  <p class="color-red">支持多种刻蚀模式:</p>
  <div class="li-column">
    <ul class="ul-list">
      <li><span>各向同性刻蚀</span></li>
      <li><span>各向异性刻蚀</span></li>
      <li><span>晶向相关的刻蚀</span></li>
      <li><span>坐标轴相关的刻蚀</span></li>
    </ul>
    <ul class="ul-list">
      <li><span>梯形刻蚀</span></li>
      <li><span>自定义刻蚀区域</span></li>
      <li><span>CMP</span></li>
    </ul>
  </div>
  <div class="img-container">
    <img src="assets/img/products/genes4.png" alt="">
  </div>

  <h3>沉积</h3>
  <p class="color-red">支持多种沉积模式:</p>
  <div class="li-column">
    <ul class="ul-list">
      <li><span>各向同性沉积</span></li>
      <li><span>各向异性沉积</span></li>
      <li><span>晶向相关的沉积</span></li>
    </ul>
    <ul class="ul-list">
      <li><span>坐标轴相关的沉积</span></li>
      <li><span>自定义沉积区域</span></li>
      <li><span>沉积后CMP</span></li>
    </ul>
  </div>
  <div class="img-container">
    <img src="assets/img/products/genes5.png" alt="">
  </div>

  <h3>离子注入</h3>
  <p class="color-red">基于解析公式的离子注入</p>
  <ul class="ul-list">
    <li><span>最终掺杂分布取决于离子能量, 剂量, 倾斜角, 旋转角, 以及表面的屏蔽层</span></li>
    <li><span>晶格损伤基于 Hobler 模型</span></li>
    <li><span>注入表进过蒙卡方法校准</span></li>
    <li><span>支持并行加速</span></li>
  </ul>
  <div class="img-container">
    <img src="assets/img/products/genes6.png" alt="">
  </div>

  <h3>扩散</h3>
  <p class="color-red">稳健的扩散模型求解器</p>
  <ul class="ul-list">
    <li><span>基于弹性PDE框架</span></li>
    <li><span>支持Fermi,  3-stream and 5-stream 模型，以及簇团动力学模型</span></li>
    <li><span>支持参数校准功能</span></li>
    <li><span>支持并行加速功能</span></li>
  </ul>
  <div class="img-container">
    <img src="assets/img/products/genes7.png" alt="">
  </div>

  <h3>氧化</h3>
  <p class="color-red">先进氧化模型求解器</p>
  <ul class="ul-list">
    <li><span>支持多种氧化模型</span></li>
    <li><span>支持动网格技术</span></li>
    <li><span>支持氧化参数校准功能</span></li>
    <li><span>支持并行加速功能</span></li>
  </ul>
  <div class="img-container">
    <img src="assets/img/products/genes8.png" alt="">
  </div>

  <h3>浅槽隔离</h3>
  <p class="color-red">STI生成器</p>
  <ul class="ul-list">
    <li><span>参数化STI建模工具</span></li>
  </ul>
  <div class="img-container">
    <img src="assets/img/products/genes9.png" alt="">
  </div>
</div>
