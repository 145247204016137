import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mover',
  templateUrl: './mover.component.html',
  styleUrls: ['./../product.component.css']
})
export class MoverComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
