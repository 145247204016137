import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-ip',
  templateUrl: './basic-ip.component.html',
  styleUrls: ['./../product.component.css']
})
export class BasicIpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
