import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  curNav:string = "address"  // address/dealers

  constructor() { }

  ngOnInit(): void {
  }

  changeNav(nav:string): void {
    const self = this;
    self.curNav = nav;
  }
}
