import { Component, OnInit } from '@angular/core';
import qaData from "./../../assets/data/qa.json";

@Component({
  selector: 'app-campus-qa',
  templateUrl: './campus-qa.component.html',
  styleUrls: ['./campus-qa.component.css']
})
export class CampusQaComponent implements OnInit {
  curQA:string = 'star';
  qa:any;

  constructor() { }

  ngOnInit(): void {
    const self = this;
    if(sessionStorage.getItem('curQA')){
      self.curQA = sessionStorage.getItem('curQA');
    }
    self.qa = qaData[self.curQA];
  }


}
