<div class="banner company-banner">
  <div class="banner-item" id="banner_1">
    <h1>Microelectronics Fabrication</h1>
    <span class="red-span">Predictable Success</span>
    <p>with computational models and data-driven processes</p>
  </div>
  <div class="banner-item" id="banner_2">
    <span class="red-span">More than Moore</span>
    <p>Multi-Physics Simulation, Modeling and Design</p>
  </div>
  <div class="banner-item" id="banner_3">
    <span class="red-span">Reach for the sky</span>
    <p>Modeling and Design Enablement for Specialty ICs</p>
  </div>
  <div class="banner-ctrl">
    <span data-id="banner_1" class="white-bgd" (click)="onBannerCtrl($event,'banner_1')"></span>
    <span data-id="banner_2" class="white-bgd" (click)="onBannerCtrl($event,'banner_2')"></span>
    <span data-id="banner_3" class="white-bgd" (click)="onBannerCtrl($event,'banner_3')"></span>
  </div>
  <ul class="icon-list">
    <li (click)="goToRouter('/product/opc')">
      <img src="assets/img/opc-icon.png" alt="OPC">
      <p>OPC</p>
    </li>
    <li (click)="goToRouter('/product/tcad')">
      <img src="assets/img/tCAD-icon.png" alt="TCAD">
      <p>TCAD</p>
    </li>
    <li (click)="goToRouter('/product/basic-ip')">
      <img src="assets/img/IP-icon.png" alt="IP" style="width:38px;">
      <p>基础IP</p>
    </li>
    <li (click)="goToRouter('/product/technology-development')">
      <img src="assets/img/service-icon.png" alt="technology">
      <p>工艺研发</p>
    </li>
  </ul>
</div>
<div class="container company">
  <h2>公司简介</h2>
  <p>苏州培风图南半导体有限公司成立于2021年，总部设立在苏州工业园区，拥有苏州珂晶达电子有限公司和墨研计算科学（南京）有限公司、方思微（上海）半导体有限公司三家全资子公司。企业核心团队由拥有20年以上的半导体行业经验的一线晶圆厂高管以及国内最早从事此类软件研发的技术先驱组成。</p>
  <p>公司是行业领先的EDA软件和工艺研发服务综合解决方案的供应商，产品包括光学邻近效应修正（OPC）软件、半导体工艺/器件仿真（TCAD）软件、多物理数值仿真软件、3D 表面结构仿真 (Emulator）、TCAD-SPICE 快速建模、电路仿真与寄生参数抽取等全系列制造类EDA软件，也为客户提供DTCO一站式“软件+服务”完整解决方案。公司产品还包括半导体抗辐射效应仿真软件、特种领域数字/高精度模拟/功率芯片开发和高度定制化芯片开发。</p>
  <img src="assets/img/company.png" alt="">
</div>
